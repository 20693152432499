import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import kv from '../images/kv.jpg'
import serviceVisual from '../images/service-visual.png'
import cardImage1 from '../images/card-image1.png'
import companyVisual from '../images/company-visual.png'

const Index = ({ location }) => {

  return (
    <Layout location={location} title="Change your home SMARTER" title2="SMARTER" subTitle="賢い生活に変わる" kv={kv} kvHeight={800}>
      <SEO title="株式会社スマートホーム" />
      <section className="contents service">
        <h2>Service</h2>
        <p>サービス</p>
        <section className="service-description">
          <div>
            <p className="service-description-main">
              賢く電気を作り・使用する時代を迎え、<br />
太陽光発電や蓄電池等の省エネ設備を取り入れた住宅が年々増えてきています。<br /><br />

継続可能なエネルギーとしての太陽光発電は環境に優しく、<br />
さらに蓄電することで災害などの緊急時に活用できます。<br /><br />

電力の他、住宅ローン見直しや家計全般の見直しを<br />
ファイナンシャルプランナーからサポート致します。</p>
            <div className="content-button-wrapper">
              <Link to="/service" className="button">View Detail</Link>
            </div>
          </div>
          <figure>
            <img src={serviceVisual} alt="サービス" />
          </figure>
        </section>
      </section>
      {/* <section className="contents even work">
        <h2>Work</h2>
        <p>施工実績</p>
        <ul className="cards">
          <li>
            <figure>
              <img src={cardImage1} alt="" />
              <figcaption>
                <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
                <p>2020.10.26 小牧市</p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <img src={cardImage1} alt="" />
              <figcaption>
                <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
                <p>2020.10.26 小牧市</p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure>
              <img src={cardImage1} alt="" />
              <figcaption>
                <h3>M様による蓄電システム（田淵電機 アイビス7）を導入</h3>
                <p>2020.10.26 小牧市</p>
              </figcaption>
            </figure>
          </li>
        </ul>
        <Link to="/work" className="button">View More</Link>
      </section> */}
      <section className="contents company">
        <figure>
          <img src={companyVisual} alt="会社概要" />
          <figcaption>
            <h2>Company</h2>
            <p>会社概要</p>
            <p className="description">スマートホームはお客様一人一人のより賢い生活のご提供に日々努力しています。<br />
太陽光発電システムや、蓄電池の提供・施工・メンテナンスを行い、<br />
ファイナンシャルプランナーによる家計見直しをサポートします。<br /><br />

エネルギッシュなメンバーがお客様の生活を賢く支えます。</p>
            <div className="content-button-wrapper">
              <Link to="/company" className="button">View Detail</Link>
            </div>
          </figcaption>
        </figure>
      </section>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
